import { Link } from "gatsby";
import * as React from "react";
import { useEffect, useState } from "react";
import content from "*.svg";
import Layout from "../components/layout";
import { H1, P } from "../components/typography";
import { StaticImage } from "gatsby-plugin-image";

const posts = [
  {
    title: "Hello World",
    href: "/learn/hello_world",
    category: {
      name: "Vessel",
      href: "/learn/hello_world",
      color: "bg-blue-100 text-blue-800",
    },
    imageUrl:
      "https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80",
    description:
      "Welcome to our first ever post. We hope you enjoy what will be coming from this in the future.",
    date: "June 13, 2021",
    datetime: "13/06/2021",
    author: {
      name: "Alfie Jones",
      href: "/learn/hello_world",
      imageUrl:
        "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
    readingTime: "1 min",
  },
];


const IndexPage = ({ location, pageContext }: any) => {
  const [offset, setOffset] = useState(0);
  useEffect(() => {
    if (location.state) window.scrollTo(0, location.state.scroll);
    if (typeof window !== `undefined`) {
      window.onscroll = () => {
        setOffset(window.pageYOffset);
      };
    }
  }, []);
  return (
    <Layout
      title="Market news, financial education and more"
      description="Vessel learn teaches and informs you about anything and everything financial. Our articles are written by experts and designed to help you on your financial journey"
      canonical="learn"
      siteName="Vessel Learn"
    >
      <header className="flex flex-col items-center pt-12">
        <H1 className="text-4xl md:text-6xl">Vessel Learn</H1>
        <P className="mt-4">A hub for all things investing</P>
      </header>
      <section className="bg-white pt-16 pb-20 px-4 sm:px-6  lg:pb-16 lg:px-8">
        <div className="relative max-w-lg mx-auto divide-y-2 divide-gray-200 lg:max-w-7xl">
          <div>
            <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
              Recent
            </h2>
            <p className="mt-3 text-xl text-gray-500 sm:mt-4">
              Follow for expert advice, company insights, money tips and much
              more.
            </p>
          </div>
          <div className="mt-6 grid gap-16 pt-6 lg:grid-cols-3 lg:gap-x-5 lg:gap-y-12">
          {posts.map((post) => (
          <article
            key={post.title}
            onClick={() => window.location.href=post.href}
            className="flex flex-col rounded-lg shadow-lg overflow-hidden transform transition-transform hover:-translate-y-3 cursor-pointer"
          >
            <div className="flex-shrink-0 z-10">
              <StaticImage
                    layout="constrained"
                    className="h-48 w-full object-cover"
                    src="./hello_world.png"
                    alt="Alfie Jones"
                    height={960}
                    width={2560}
                    quality={80}
              />
            </div>
            <div className="flex-1 bg-white p-6 flex flex-col justify-between z-10">
              <div className="flex-1">
                <p className="text-sm font-medium text-blue-600 hover:underline"  onClick={() => window.location.href=post.category.href}>
                    {post.category.name}
                </p>
                <div className="block mt-2">
                  <p className="text-xl font-semibold text-gray-900">
                    {post.title}
                  </p>
                  <p className="mt-3 text-base text-gray-500">
                    {post.description}
                  </p>
                </div>
              </div>
              <div className="mt-6 flex items-center">
                  <StaticImage
                    layout="fixed"
                    className="h-10 w-10 rounded-full"
                    src="./face-cropped.jpg"
                    alt="Alfie Jones"
                    height={60}
                    width={60}
                    quality={100}
                />
                <div className="ml-3">
                  <p className="text-sm font-medium text-gray-900 hover:underline" onClick={() => window.location.href=post.author.href}>
                      {post.author.name}
                  </p>
                  <div className="flex space-x-1 text-sm text-gray-500">
                    <time dateTime={post.datetime}>{post.date}</time>
                    <span aria-hidden="true">&middot;</span>
                    <span>{post.readingTime} read</span>
                  </div>
                </div>
              </div>
            </div>
            <Link
              to="/about-vessel"
              className="absolute top-0 right-0 bottom-0 left-0"
            />
          </article>
        ))}
          </div>
        </div>
      </section>
      {/** 
      <nav>
        <ol className="flex flex-wrap justify-center">
          {pageContext.tags.map((tag: any) => (
            <li>
              <Link
                activeClassName="text-green-500"
                className="hover:shadow m-8 py-2 px-4 text-gray-500 text-lg"
                state={{
                  scroll: offset,
                }}
                to={tag.link}
              >
                {tag.name}
              </Link>
            </li>
          ))}
        </ol>
      </nav>
      <div id="content">
        <P>
          Our first post -&gt; <Link to="/learn/hello_world/">Hello World</Link>
        </P>
      </div>
      */}
    </Layout>
  );
};

export default IndexPage;
